export function base64ToFile (dataURI, filename) {
  if (!dataURI) return ''
  const byteString = atob(dataURI.split(',')[1])
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  const blob = new Blob([ab], { type: mimeString })
  return new File([blob], `${filename}${mimeString.replace('image/', '.')}`, { type: mimeString })
}

export const createLicenseStepsFields = {
  user: {
    fields: ['admin_username', 'admin_password', 'new_password', 'admin_first_name', 'admin_last_name']
  },
  season: {
    fields: ['league_country', 'season_title', 'season_date_started', 'season_date_finished', 'league_name']
  },
  branding: {
    fields: ['branding_title_tournament', 'branding_title_championship', 'branding_logo_tournament', 'branding_logo_championship', 'branding_option_tournament', 'branding_option_championship']
  },
  license: {
    fields: ['teams_amount', 'description', 'exp_date', 'leagues_amount', 'league_logo', 'title', 'logo']
  },
  agreement: {
    fields: ['agreement_accepted', 'signature']
  }
}

export function tableHeadsUniformChooseTeams ($t) {
  return [
    {
      text: $t('country'),
      value: 'country'
    },
    {
      text: $t('type'),
      value: 'type'
    },
    {
      text: $t('league'),
      value: 'league'
    },
    {
      text: $t('team'),
      value: 'name'
    },
    {
      text: $t('players'),
      value: 'playerCount'
    }
  ]
}

export const countries = [
  {
    id: 2,
    countryCode: '004',
    countryName: 'Afghanistan'
  },
  {
    id: 5,
    countryCode: '248',
    countryName: 'Åland Islands'
  },
  {
    id: 6,
    countryCode: '008',
    countryName: 'Albania'
  },
  {
    id: 65,
    countryCode: '012',
    countryName: 'Algeria'
  },
  {
    id: 11,
    countryCode: '016',
    countryName: 'American Samoa'
  },
  {
    id: 7,
    countryCode: '020',
    countryName: 'Andorra'
  },
  {
    id: 3,
    countryCode: '024',
    countryName: 'Angola'
  },
  {
    id: 4,
    countryCode: '660',
    countryName: 'Anguilla'
  },
  {
    id: 12,
    countryCode: '010',
    countryName: 'Antarctica'
  },
  {
    id: 14,
    countryCode: '028',
    countryName: 'Antigua and Barbuda'
  },
  {
    id: 9,
    countryCode: '032',
    countryName: 'Argentina'
  },
  {
    id: 10,
    countryCode: '051',
    countryName: 'Armenia'
  },
  {
    id: 1,
    countryCode: '533',
    countryName: 'Aruba'
  },
  {
    id: 15,
    countryCode: '036',
    countryName: 'Australia'
  },
  {
    id: 16,
    countryCode: '040',
    countryName: 'Austria'
  },
  {
    id: 17,
    countryCode: '031',
    countryName: 'Azerbaijan'
  },
  {
    id: 26,
    countryCode: '044',
    countryName: 'Bahamas'
  },
  {
    id: 25,
    countryCode: '048',
    countryName: 'Bahrain'
  },
  {
    id: 23,
    countryCode: '050',
    countryName: 'Bangladesh'
  },
  {
    id: 34,
    countryCode: '052',
    countryName: 'Barbados'
  },
  {
    id: 29,
    countryCode: '112',
    countryName: 'Belarus'
  },
  {
    id: 19,
    countryCode: '056',
    countryName: 'Belgium'
  },
  {
    id: 30,
    countryCode: '084',
    countryName: 'Belize'
  },
  {
    id: 20,
    countryCode: '204',
    countryName: 'Benin'
  },
  {
    id: 31,
    countryCode: '060',
    countryName: 'Bermuda'
  },
  {
    id: 36,
    countryCode: '064',
    countryName: 'Bhutan'
  },
  {
    id: 32,
    countryCode: '068',
    countryName: 'Bolivia, Plurinational State of'
  },
  {
    id: 21,
    countryCode: '535',
    countryName: 'Bonaire, Sint Eustatius and Saba'
  },
  {
    id: 27,
    countryCode: '070',
    countryName: 'Bosnia and Herzegovina'
  },
  {
    id: 38,
    countryCode: '072',
    countryName: 'Botswana'
  },
  {
    id: 37,
    countryCode: '074',
    countryName: 'Bouvet Island'
  },
  {
    id: 33,
    countryCode: '076',
    countryName: 'Brazil'
  },
  {
    id: 106,
    countryCode: '086',
    countryName: 'British Indian Ocean Territory'
  },
  {
    id: 35,
    countryCode: '096',
    countryName: 'Brunei Darussalam'
  },
  {
    id: 24,
    countryCode: '100',
    countryName: 'Bulgaria'
  },
  {
    id: 22,
    countryCode: '854',
    countryName: 'Burkina Faso'
  },
  {
    id: 18,
    countryCode: '108',
    countryName: 'Burundi'
  },
  {
    id: 52,
    countryCode: '132',
    countryName: 'Cabo Verde'
  },
  {
    id: 120,
    countryCode: '116',
    countryName: 'Cambodia'
  },
  {
    id: 46,
    countryCode: '120',
    countryName: 'Cameroon'
  },
  {
    id: 40,
    countryCode: '124',
    countryName: 'Canada'
  },
  {
    id: 57,
    countryCode: '136',
    countryName: 'Cayman Islands'
  },
  {
    id: 39,
    countryCode: '140',
    countryName: 'Central African Republic'
  },
  {
    id: 217,
    countryCode: '148',
    countryName: 'Chad'
  },
  {
    id: 43,
    countryCode: '152',
    countryName: 'Chile'
  },
  {
    id: 44,
    countryCode: '156',
    countryName: 'China'
  },
  {
    id: 56,
    countryCode: '162',
    countryName: 'Christmas Island'
  },
  {
    id: 41,
    countryCode: '166',
    countryName: 'Cocos (Keeling) Islands'
  },
  {
    id: 50,
    countryCode: '170',
    countryName: 'Colombia'
  },
  {
    id: 51,
    countryCode: '174',
    countryName: 'Comoros'
  },
  {
    id: 48,
    countryCode: '178',
    countryName: 'Congo'
  },
  {
    id: 47,
    countryCode: '180',
    countryName: 'Congo, The Democratic Republic of the'
  },
  {
    id: 49,
    countryCode: '184',
    countryName: 'Cook Islands'
  },
  {
    id: 53,
    countryCode: '188',
    countryName: 'Costa Rica'
  },
  {
    id: 45,
    countryCode: '384',
    countryName: "Côte d'Ivoire"
  },
  {
    id: 100,
    countryCode: '191',
    countryName: 'Croatia'
  },
  {
    id: 54,
    countryCode: '192',
    countryName: 'Cuba'
  },
  {
    id: 55,
    countryCode: '531',
    countryName: 'Curaçao'
  },
  {
    id: 58,
    countryCode: '196',
    countryName: 'Cyprus'
  },
  {
    id: 59,
    countryCode: '203',
    countryName: 'Czechia'
  },
  {
    id: 63,
    countryCode: '208',
    countryName: 'Denmark'
  },
  {
    id: 61,
    countryCode: '262',
    countryName: 'Djibouti'
  },
  {
    id: 62,
    countryCode: '212',
    countryName: 'Dominica'
  },
  {
    id: 64,
    countryCode: '214',
    countryName: 'Dominican Republic'
  },
  {
    id: 66,
    countryCode: '218',
    countryName: 'Ecuador'
  },
  {
    id: 67,
    countryCode: '818',
    countryName: 'Egypt'
  },
  {
    id: 201,
    countryCode: '222',
    countryName: 'El Salvador'
  },
  {
    id: 89,
    countryCode: '226',
    countryName: 'Equatorial Guinea'
  },
  {
    id: 68,
    countryCode: '232',
    countryName: 'Eritrea'
  },
  {
    id: 71,
    countryCode: '233',
    countryName: 'Estonia'
  },
  {
    id: 212,
    countryCode: '748',
    countryName: 'Eswatini'
  },
  {
    id: 72,
    countryCode: '231',
    countryName: 'Ethiopia'
  },
  {
    id: 75,
    countryCode: '238',
    countryName: 'Falkland Islands (Malvinas)'
  },
  {
    id: 77,
    countryCode: '234',
    countryName: 'Faroe Islands'
  },
  {
    id: 74,
    countryCode: '242',
    countryName: 'Fiji'
  },
  {
    id: 73,
    countryCode: '246',
    countryName: 'Finland'
  },
  {
    id: 76,
    countryCode: '250',
    countryName: 'France'
  },
  {
    id: 94,
    countryCode: '254',
    countryName: 'French Guiana'
  },
  {
    id: 186,
    countryCode: '258',
    countryName: 'French Polynesia'
  },
  {
    id: 13,
    countryCode: '260',
    countryName: 'French Southern Territories'
  },
  {
    id: 79,
    countryCode: '266',
    countryName: 'Gabon'
  },
  {
    id: 87,
    countryCode: '270',
    countryName: 'Gambia'
  },
  {
    id: 81,
    countryCode: '268',
    countryName: 'Georgia'
  },
  {
    id: 60,
    countryCode: '276',
    countryName: 'Germany'
  },
  {
    id: 83,
    countryCode: '288',
    countryName: 'Ghana'
  },
  {
    id: 84,
    countryCode: '292',
    countryName: 'Gibraltar'
  },
  {
    id: 90,
    countryCode: '300',
    countryName: 'Greece'
  },
  {
    id: 92,
    countryCode: '304',
    countryName: 'Greenland'
  },
  {
    id: 91,
    countryCode: '308',
    countryName: 'Grenada'
  },
  {
    id: 86,
    countryCode: '312',
    countryName: 'Guadeloupe'
  },
  {
    id: 95,
    countryCode: '316',
    countryName: 'Guam'
  },
  {
    id: 93,
    countryCode: '320',
    countryName: 'Guatemala'
  },
  {
    id: 82,
    countryCode: '831',
    countryName: 'Guernsey'
  },
  {
    id: 85,
    countryCode: '324',
    countryName: 'Guinea'
  },
  {
    id: 88,
    countryCode: '624',
    countryName: 'Guinea-Bissau'
  },
  {
    id: 96,
    countryCode: '328',
    countryName: 'Guyana'
  },
  {
    id: 101,
    countryCode: '332',
    countryName: 'Haiti'
  },
  {
    id: 98,
    countryCode: '334',
    countryName: 'Heard Island and McDonald Islands'
  },
  {
    id: 237,
    countryCode: '336',
    countryName: 'Holy See (Vatican City State)'
  },
  {
    id: 99,
    countryCode: '340',
    countryName: 'Honduras'
  },
  {
    id: 97,
    countryCode: '344',
    countryName: 'Hong Kong'
  },
  {
    id: 102,
    countryCode: '348',
    countryName: 'Hungary'
  },
  {
    id: 110,
    countryCode: '352',
    countryName: 'Iceland'
  },
  {
    id: 105,
    countryCode: '356',
    countryName: 'India'
  },
  {
    id: 103,
    countryCode: '360',
    countryName: 'Indonesia'
  },
  {
    id: 108,
    countryCode: '364',
    countryName: 'Iran, Islamic Republic of'
  },
  {
    id: 109,
    countryCode: '368',
    countryName: 'Iraq'
  },
  {
    id: 107,
    countryCode: '372',
    countryName: 'Ireland'
  },
  {
    id: 104,
    countryCode: '833',
    countryName: 'Isle of Man'
  },
  {
    id: 111,
    countryCode: '376',
    countryName: 'Israel'
  },
  {
    id: 112,
    countryCode: '380',
    countryName: 'Italy'
  },
  {
    id: 113,
    countryCode: '388',
    countryName: 'Jamaica'
  },
  {
    id: 116,
    countryCode: '392',
    countryName: 'Japan'
  },
  {
    id: 114,
    countryCode: '832',
    countryName: 'Jersey'
  },
  {
    id: 115,
    countryCode: '400',
    countryName: 'Jordan'
  },
  {
    id: 117,
    countryCode: '398',
    countryName: 'Kazakhstan'
  },
  {
    id: 118,
    countryCode: '404',
    countryName: 'Kenya'
  },
  {
    id: 121,
    countryCode: '296',
    countryName: 'Kiribati'
  },
  {
    id: 182,
    countryCode: '408',
    countryName: "Korea, Democratic People's Republic of"
  },
  {
    id: 123,
    countryCode: '410',
    countryName: 'Korea, Republic of'
  },
  {
    id: 124,
    countryCode: '414',
    countryName: 'Kuwait'
  },
  {
    id: 119,
    countryCode: '417',
    countryName: 'Kyrgyzstan'
  },
  {
    id: 125,
    countryCode: '418',
    countryName: "Lao People's Democratic Republic"
  },
  {
    id: 135,
    countryCode: '428',
    countryName: 'Latvia'
  },
  {
    id: 126,
    countryCode: '422',
    countryName: 'Lebanon'
  },
  {
    id: 132,
    countryCode: '426',
    countryName: 'Lesotho'
  },
  {
    id: 127,
    countryCode: '430',
    countryName: 'Liberia'
  },
  {
    id: 128,
    countryCode: '434',
    countryName: 'Libya'
  },
  {
    id: 130,
    countryCode: '438',
    countryName: 'Liechtenstein'
  },
  {
    id: 133,
    countryCode: '440',
    countryName: 'Lithuania'
  },
  {
    id: 134,
    countryCode: '442',
    countryName: 'Luxembourg'
  },
  {
    id: 136,
    countryCode: '446',
    countryName: 'Macao'
  },
  {
    id: 141,
    countryCode: '450',
    countryName: 'Madagascar'
  },
  {
    id: 157,
    countryCode: '454',
    countryName: 'Malawi'
  },
  {
    id: 158,
    countryCode: '458',
    countryName: 'Malaysia'
  },
  {
    id: 142,
    countryCode: '462',
    countryName: 'Maldives'
  },
  {
    id: 146,
    countryCode: '466',
    countryName: 'Mali'
  },
  {
    id: 147,
    countryCode: '470',
    countryName: 'Malta'
  },
  {
    id: 144,
    countryCode: '584',
    countryName: 'Marshall Islands'
  },
  {
    id: 155,
    countryCode: '474',
    countryName: 'Martinique'
  },
  {
    id: 153,
    countryCode: '478',
    countryName: 'Mauritania'
  },
  {
    id: 156,
    countryCode: '480',
    countryName: 'Mauritius'
  },
  {
    id: 159,
    countryCode: '175',
    countryName: 'Mayotte'
  },
  {
    id: 143,
    countryCode: '484',
    countryName: 'Mexico'
  },
  {
    id: 78,
    countryCode: '583',
    countryName: 'Micronesia, Federated States of'
  },
  {
    id: 140,
    countryCode: '498',
    countryName: 'Moldova, Republic of'
  },
  {
    id: 139,
    countryCode: '492',
    countryName: 'Monaco'
  },
  {
    id: 150,
    countryCode: '496',
    countryName: 'Mongolia'
  },
  {
    id: 149,
    countryCode: '499',
    countryName: 'Montenegro'
  },
  {
    id: 154,
    countryCode: '500',
    countryName: 'Montserrat'
  },
  {
    id: 138,
    countryCode: '504',
    countryName: 'Morocco'
  },
  {
    id: 152,
    countryCode: '508',
    countryName: 'Mozambique'
  },
  {
    id: 148,
    countryCode: '104',
    countryName: 'Myanmar'
  },
  {
    id: 160,
    countryCode: '516',
    countryName: 'Namibia'
  },
  {
    id: 170,
    countryCode: '520',
    countryName: 'Nauru'
  },
  {
    id: 169,
    countryCode: '524',
    countryName: 'Nepal'
  },
  {
    id: 167,
    countryCode: '528',
    countryName: 'Netherlands'
  },
  {
    id: 161,
    countryCode: '540',
    countryName: 'New Caledonia'
  },
  {
    id: 171,
    countryCode: '554',
    countryName: 'New Zealand'
  },
  {
    id: 165,
    countryCode: '558',
    countryName: 'Nicaragua'
  },
  {
    id: 162,
    countryCode: '562',
    countryName: 'Niger'
  },
  {
    id: 164,
    countryCode: '566',
    countryName: 'Nigeria'
  },
  {
    id: 166,
    countryCode: '570',
    countryName: 'Niue'
  },
  {
    id: 163,
    countryCode: '574',
    countryName: 'Norfolk Island'
  },
  {
    id: 151,
    countryCode: '580',
    countryName: 'Northern Mariana Islands'
  },
  {
    id: 145,
    countryCode: '807',
    countryName: 'North Macedonia'
  },
  {
    id: 168,
    countryCode: '578',
    countryName: 'Norway'
  },
  {
    id: 172,
    countryCode: '512',
    countryName: 'Oman'
  },
  {
    id: 173,
    countryCode: '586',
    countryName: 'Pakistan'
  },
  {
    id: 178,
    countryCode: '585',
    countryName: 'Palau'
  },
  {
    id: 185,
    countryCode: '275',
    countryName: 'Palestine, State of'
  },
  {
    id: 174,
    countryCode: '591',
    countryName: 'Panama'
  },
  {
    id: 179,
    countryCode: '598',
    countryName: 'Papua New Guinea'
  },
  {
    id: 184,
    countryCode: '600',
    countryName: 'Paraguay'
  },
  {
    id: 176,
    countryCode: '604',
    countryName: 'Peru'
  },
  {
    id: 177,
    countryCode: '608',
    countryName: 'Philippines'
  },
  {
    id: 175,
    countryCode: '612',
    countryName: 'Pitcairn'
  },
  {
    id: 180,
    countryCode: '616',
    countryName: 'Poland'
  },
  {
    id: 183,
    countryCode: '620',
    countryName: 'Portugal'
  },
  {
    id: 181,
    countryCode: '630',
    countryName: 'Puerto Rico'
  },
  {
    id: 187,
    countryCode: '634',
    countryName: 'Qatar'
  },
  {
    id: 188,
    countryCode: '638',
    countryName: 'Réunion'
  },
  {
    id: 189,
    countryCode: '642',
    countryName: 'Romania'
  },
  {
    id: 190,
    countryCode: '643',
    countryName: 'Russian Federation'
  },
  {
    id: 191,
    countryCode: '646',
    countryName: 'Rwanda'
  },
  {
    id: 28,
    countryCode: '652',
    countryName: 'Saint Barthélemy'
  },
  {
    id: 197,
    countryCode: '654',
    countryName: 'Saint Helena, Ascension and Tristan da Cunha'
  },
  {
    id: 122,
    countryCode: '659',
    countryName: 'Saint Kitts and Nevis'
  },
  {
    id: 129,
    countryCode: '662',
    countryName: 'Saint Lucia'
  },
  {
    id: 137,
    countryCode: '663',
    countryName: 'Saint Martin (French part)'
  },
  {
    id: 204,
    countryCode: '666',
    countryName: 'Saint Pierre and Miquelon'
  },
  {
    id: 238,
    countryCode: '670',
    countryName: 'Saint Vincent and the Grenadines'
  },
  {
    id: 245,
    countryCode: '882',
    countryName: 'Samoa'
  },
  {
    id: 202,
    countryCode: '674',
    countryName: 'San Marino'
  },
  {
    id: 207,
    countryCode: '678',
    countryName: 'Sao Tome and Principe'
  },
  {
    id: 192,
    countryCode: '682',
    countryName: 'Saudi Arabia'
  },
  {
    id: 194,
    countryCode: '686',
    countryName: 'Senegal'
  },
  {
    id: 205,
    countryCode: '688',
    countryName: 'Serbia'
  },
  {
    id: 214,
    countryCode: '690',
    countryName: 'Seychelles'
  },
  {
    id: 200,
    countryCode: '694',
    countryName: 'Sierra Leone'
  },
  {
    id: 195,
    countryCode: '702',
    countryName: 'Singapore'
  },
  {
    id: 213,
    countryCode: '534',
    countryName: 'Sint Maarten (Dutch part)'
  },
  {
    id: 209,
    countryCode: '703',
    countryName: 'Slovakia'
  },
  {
    id: 210,
    countryCode: '705',
    countryName: 'Slovenia'
  },
  {
    id: 199,
    countryCode: '090',
    countryName: 'Solomon Islands'
  },
  {
    id: 203,
    countryCode: '706',
    countryName: 'Somalia'
  },
  {
    id: 247,
    countryCode: '710',
    countryName: 'South Africa'
  },
  {
    id: 196,
    countryCode: '239',
    countryName: 'South Georgia and the South Sandwich Islands'
  },
  {
    id: 206,
    countryCode: '728',
    countryName: 'South Sudan'
  },
  {
    id: 70,
    countryCode: '724',
    countryName: 'Spain'
  },
  {
    id: 131,
    countryCode: '144',
    countryName: 'Sri Lanka'
  },
  {
    id: 193,
    countryCode: '729',
    countryName: 'Sudan'
  },
  {
    id: 208,
    countryCode: '740',
    countryName: 'Suriname'
  },
  {
    id: 198,
    countryCode: '744',
    countryName: 'Svalbard and Jan Mayen'
  },
  {
    id: 211,
    countryCode: '752',
    countryName: 'Sweden'
  },
  {
    id: 42,
    countryCode: '756',
    countryName: 'Switzerland'
  },
  {
    id: 215,
    countryCode: '760',
    countryName: 'Syrian Arab Republic'
  },
  {
    id: 229,
    countryCode: '158',
    countryName: 'Taiwan, Province of China'
  },
  {
    id: 220,
    countryCode: '762',
    countryName: 'Tajikistan'
  },
  {
    id: 230,
    countryCode: '834',
    countryName: 'Tanzania, United Republic of'
  },
  {
    id: 219,
    countryCode: '764',
    countryName: 'Thailand'
  },
  {
    id: 223,
    countryCode: '626',
    countryName: 'Timor-Leste'
  },
  {
    id: 218,
    countryCode: '768',
    countryName: 'Togo'
  },
  {
    id: 221,
    countryCode: '772',
    countryName: 'Tokelau'
  },
  {
    id: 224,
    countryCode: '776',
    countryName: 'Tonga'
  },
  {
    id: 225,
    countryCode: '780',
    countryName: 'Trinidad and Tobago'
  },
  {
    id: 226,
    countryCode: '788',
    countryName: 'Tunisia'
  },
  {
    id: 227,
    countryCode: '792',
    countryName: 'Turkey'
  },
  {
    id: 222,
    countryCode: '795',
    countryName: 'Turkmenistan'
  },
  {
    id: 216,
    countryCode: '796',
    countryName: 'Turks and Caicos Islands'
  },
  {
    id: 228,
    countryCode: '798',
    countryName: 'Tuvalu'
  },
  {
    id: 231,
    countryCode: '800',
    countryName: 'Uganda'
  },
  {
    id: 232,
    countryCode: '804',
    countryName: 'Ukraine'
  },
  {
    id: 8,
    countryCode: '784',
    countryName: 'United Arab Emirates'
  },
  {
    id: 80,
    countryCode: '826',
    countryName: 'United Kingdom'
  },
  {
    id: 235,
    countryCode: '840',
    countryName: 'United States'
  },
  {
    id: 233,
    countryCode: '581',
    countryName: 'United States Minor Outlying Islands'
  },
  {
    id: 234,
    countryCode: '858',
    countryName: 'Uruguay'
  },
  {
    id: 236,
    countryCode: '860',
    countryName: 'Uzbekistan'
  },
  {
    id: 243,
    countryCode: '548',
    countryName: 'Vanuatu'
  },
  {
    id: 239,
    countryCode: '862',
    countryName: 'Venezuela, Bolivarian Republic of'
  },
  {
    id: 242,
    countryCode: '704',
    countryName: 'Viet Nam'
  },
  {
    id: 240,
    countryCode: '092',
    countryName: 'Virgin Islands, British'
  },
  {
    id: 241,
    countryCode: '850',
    countryName: 'Virgin Islands, U.S.'
  },
  {
    id: 244,
    countryCode: '876',
    countryName: 'Wallis and Futuna'
  },
  {
    id: 69,
    countryCode: '732',
    countryName: 'Western Sahara'
  },
  {
    id: 246,
    countryCode: '887',
    countryName: 'Yemen'
  },
  {
    id: 248,
    countryCode: '894',
    countryName: 'Zambia'
  },
  {
    id: 249,
    countryCode: '716',
    countryName: 'Zimbabwe'
  }
]
