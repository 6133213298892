import api from '@/api'
const camelcaseObjectDeep = require('camelcase-object-deep')

const state = {
  isLoading: false,
  teams: null,
  sponsorshipInfo: null
}

const getters = {
  isLoading: state => state.isLoading,
  teams: state => state.teams,
  sponsorshipInfo: state => state.sponsorshipInfo
}

const actions = {
  async getSponsorshipInfo ({ commit }, payload) {
    try {
      commit('changeLoading', true)
      const response = await api.sponsorship.getSponsorshipInfo(payload)
      commit('setSponsorshipInfo', response)
    } catch (e) {
      throw e.response
    } finally {
      commit('changeLoading', false)
    }
  },
  async getTeams ({ commit }, payload) {
    try {
      commit('changeLoading', true)
      const response = await api.sponsorship.getTeams(payload)
      commit('setTeams', camelcaseObjectDeep(response))
    } catch (e) {
      throw e.response
    } finally {
      commit('changeLoading', false)
    }
  }
}

const mutations = {
  changeLoading (state, status) {
    state.isLoading = status
  },
  setSponsorshipInfo (state, data) {
    state.sponsorshipInfo = data
  },
  setTeams (state, data) {
    state.teams = data
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
