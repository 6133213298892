import apiReq from './request'

const campaignApiUrl = `${process.env.VUE_APP_CAMPAIGN_API}`
const leagueApi = process.env.VUE_APP_LIVE_LEAGUE_API
const limit = 15

export default {
  getSponsorshipInfo ({ slug }) {
    return apiReq.get(`${campaignApiUrl}/campaigns-types-demo/${slug}/`)
  },
  getTeams ({ page = 1, name = '', division = '', league = '', country = '', type = '', ids = null, hasGames = '', orderStatus = 'sponsorship' }) {
    // eslint-disable-next-line no-mixed-operators
    return apiReq.get(`${leagueApi}/teams/?name=${name}&id=${ids && ids.join('&id=') || ''}&country=${country && country.id}&league=${league && league.id}&division=${division}&has_games=${hasGames}&sport_type=${type && type.slug}&is_approved=true&order_status=${orderStatus}&offset=${(page - 1) * limit}&limit=${limit}`)
  }
}
